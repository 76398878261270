import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faHome,
  faMapLocation,
  faCalendar,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Home-en/Footer-en/Footer-en";
import { Swiper, SwiperSlide } from "swiper/react";
import NavBar from "../Pages-sections-en/Pages-nav-en/NavBar-en";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import LandingContactEn from "../../components/LandingContactEn";

const LandingPageEn = () => {
  return (
    <div className="landing land_en_custom" id="landing" lang="en">
      <div className="Contact-us-Header Page-Header-en">
        <NavBar currentPage={"Work"} />
        <div className="page-header-title  land_en_custom">
          <h2>Real estate house idea!</h2>

          <div className="title-nav ">
            <a href="/" className="icon-link" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faHome} />
            </a>

            <FontAwesomeIcon className="arrow-link" icon={faChevronLeft} />

            <a href="/">
              <h5> Home Page</h5>
            </a>
          </div>
        </div>
      </div>

      <div className="custom-landing-body Page-Body land_en_custom">
        <h2 className="section-title-en">Our most important projects</h2>
        <div className="landing__page-container">
          <div className="landing__share container__lan land_en_custom btns_custom_eng">
            <button className="custom__button ">
              <a href="#Home-Interest" className="gap land_en_custom">
                <FontAwesomeIcon icon={faCalendar} />
                <span>Register your interests</span>
              </a>
            </button>
            {/* <button className="custom__button ">
              <a
                href="https://maps.app.goo.gl/DvZubumG7aqP77SX9"
                target="_blank"
                rel="noreferrer"
                className="gap land_en_custom"
                download
              >
                <FontAwesomeIcon icon={faBuilding} />
                <span>Access to the apartments</span>
              </a>
            </button> */}
            <button className="custom__button">
              <a
                href="/my-residence.pdf"
                className="gap land_en_custom"
                download
              >
                <FontAwesomeIcon icon={faBuilding} />
                <span>Download the brochure</span>
              </a>
            </button>
          </div>
          <div className="landing__swiper-container land_en_custom">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper land_en_custom"
            >
              <SwiperSlide className="landing__swiper-slide">
                <img src="/land/1.jpg" alt="img" />
              </SwiperSlide>
              <SwiperSlide className="landing__swiper-slide">
                <img src="/land/2.jpg" alt="img" />
              </SwiperSlide>
              <SwiperSlide className="landing__swiper-slide">
                <img src="/land/3.jpg" alt="img" />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="landing__project-info container__lan land_en_custom">
            <div className="lan__mid info land_en_custom_2">
              <h2 className="title">Project details</h2>
              <div className="project-info land_en_custom_2 ">
                <div className="land_en_custom_2">
                  <h2 className="land_en_custom_2">Project Type:</h2>
                  <p>Residential projects</p>
                </div>
                <div>
                  <h2 className="land_en_custom_2">City:</h2>
                  <p>Riyadh</p>
                </div>
              </div>
              <div className="project-info">
                <div>
                  <h2 className="land_en_custom_2">The project includes:</h2>
                  <p>88 apartments and 30 floors</p>
                </div>
                <div>
                  <h2 className="land_en_custom_2">Project units:</h2>
                  <p>Residential projects</p>
                </div>
              </div>
              <div className="project-info">
                <div>
                  <h2 className="land_en_custom_2">Project year:</h2>
                  <p>2024</p>
                </div>
              </div>
            </div>
            <div className="lan__mid">
              <h2 className="title land_en_custom_2">Project location</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.618855274989!2d46.7731645!3d24.8427048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2eff004c3cc453%3A0x157074a5c55d538a!2sMay%20Residence%20103!5e0!3m2!1sen!2str!4v1727977826870!5m2!1sen!2str"
                width="600"
                height="400"
                className="landing__map lan__mid custom__map_en"
                title="project map"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <LandingContactEn />
      <Footer />
    </div>
  );
};

export default LandingPageEn;
