import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "../Home/Header/NavBar";
import {
  faChevronLeft,
  faHome,
  faMapLocation,
  faCalendar,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Home/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import LandingContact from "../../components/LandingContact";

const Projects = () => {
  return (
    <div className="landing" id="landing" lang="ar">
      <div className="Contact-us-Header Page-Header">
        <NavBar currentPage="landing" />
        <div className=" page-header-title">
          <h2> فكرة منزل العقارية !</h2>

          <div className="title-nav">
            <a href="/" className="icon-link" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faHome} />
            </a>

            <FontAwesomeIcon className="arrow-link" icon={faChevronLeft} />

            <a href="/">
              <h5> الصفحة الرئيسية</h5>
            </a>
          </div>
        </div>
      </div>

      <div className="custom-landing-body Page-Body">
        <h2 className=" section-title">مشروع شقق فاخرة للتمليك</h2>
        <div className="landing__page-container">
          <div className="landing__share container__lan btns_custom_eng">
            {/*<button className="custom__button">
              <a href="#Home-Interest" className="gap ">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="btns_custom">سجل اهتماماتك</span>
              </a>
            </button> */}
            { 
                  <button className="custom__button">
              <a
                href="https://maps.app.goo.gl/mgcLhRjketoEPRdK9"
                target="_blank"
                rel="noreferrer"
                className="gap "
                download
              >
                <FontAwesomeIcon icon={faBuilding} />
                <span className="btns_custom">الوصول الى الشقق </span>
              </a>
            </button> }
          { <button className="custom__button">
              <a href="tel:00966566669323" className="gap " download>
                <FontAwesomeIcon icon={faBuilding} />
                <span className="btns_custom">اتصل بنا</span>
              </a>
            </button>}
          </div>
          <div className="landing__swiper-container">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide className="landing__swiper-slide">
                <img src="/project/1.jpg" alt="img" />
              </SwiperSlide>
              <SwiperSlide className="landing__swiper-slide">
                <img src="/project/2.jpg" alt="img" />
              </SwiperSlide>
              <SwiperSlide className="landing__swiper-slide">
                <img src="/project/3.jpg" alt="img" />
              </SwiperSlide>
                <SwiperSlide className="landing__swiper-slide">
                <img src="/project/4.jpg" alt="img" />
              </SwiperSlide>
                <SwiperSlide className="landing__swiper-slide">
                <img src="/project/5.jpg" alt="img" />
              </SwiperSlide>
                <SwiperSlide className="landing__swiper-slide">
                <img src="/project/6.jpg" alt="img" />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="landing__project-info container__lan">
            <div className="lan__mid info">
              <h2 className="title">تفاصيل المشروع</h2>
              <div className="project-info">
                <div>
                  <h2>نوع المشـروع:</h2>
                  <p>المشاريع السكـنية</p>
                </div>
                <div>
                  <h2>المدينة:</h2>
                  <p>الرياض</p>
                </div>
              </div>
              <div className="project-info">
                <div>
                  <h2>يشتمل المشروع:</h2>
                  <p>شقق</p>
                </div>
                <div>
                  <h2>وحدات المشروع:</h2>
                  <p>المشاريع السكـنية</p>
                </div>
              </div>
              <div className="project-info">
                <div>
                  <h2>سنة المشروع:</h2>
                  <p>2024</p>
                </div>
              </div>
            </div>
            <div className="lan__mid">
              <h2 className="title">موقع المشروع</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3620.0848636255278!2d46.670626!3d24.860951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDUxJzM5LjQiTiA0NsKwNDAnMTQuMyJF!5e0!3m2!1sar!2ssa!4v1728507470436!5m2!1sar!2ssa"
                width="600"
                height="400"
                className="landing__map lan__mid"
                title="project map"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <LandingContact />
      <Footer />
    </div>
  );
};

export default Projects;
