import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";

function LandingContactEn() {
  const titleRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const formRef = useRef(null); // Create a ref for the form element

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const title = titleRef.current;
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;

    if (title) {
      title.classList.add("hidden");
      title.dataset.animation = "fade-in-left";
      observer.observe(title);
    }

    if (section1) {
      section1.classList.add("hidden");
      section1.dataset.animation = "fade-in-left";
      observer.observe(section1);
    }

    if (section2) {
      section2.classList.add("hidden");
      section2.dataset.animation = "fade-in-right";
      observer.observe(section2);
    }

    return () => {
      if (title) observer.unobserve(title);
      if (section1) observer.unobserve(section1);
      if (section2) observer.unobserve(section2);
    };
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    how_to_buy: "",
    housing_support: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Add onFocus event handler to capture autofilled values
  const handleAutofill = (e) => {
    const { name, value } = e.target;
    // Check if the value is different from the current form state
    if (formData[name] !== value) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_d61r6to", "template_v4evd9j", formRef.current, {
        publicKey: "Yh58BYGGAyllXF9LB",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          // Reset the form after successful submission
          setFormData({
            fullName: "",
            phoneNumber: "",
            how_to_buy: "",
            housing_support: "",
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div
      className="Home-Interest LandingContactEn"
      id="Home-Interest"
      lang="en"
    >
      <h2 className="Interest-section-title-en section-title-en" ref={titleRef}>
        {" "}
        Register your interest
      </h2>
      <div className="Interest-body">
        <div className="Interest-info-column" ref={section1Ref}>
          <h2>Enter your data to be contacted by our specialists</h2>
          <p> Unprecedented levels of quality in service</p>
          <div className="line-container">
            <span className="line"></span>
            <span className="for-q">Or for any questions</span>
            <span className="line"></span>
          </div>
          <a href="/en/contact-us">
            {" "}
            <button> Contact us</button>{" "}
          </a>
        </div>

        <div className="Interest-form-column" ref={section2Ref}>
          <form className="Interest-form" onSubmit={handleSubmit} ref={formRef}>
            <div className="form-group  form-group-en">
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                onFocus={handleAutofill}
                required
                placeholder=" "
              />
              <label htmlFor="fullName">Name </label>
            </div>
            <div className="form-group form-group-en">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                onFocus={handleAutofill}
                required
                placeholder=" "
              />
              <label htmlFor="phoneNumber">Phone number</label>
            </div>
            <div className="form-group form-group-en">
              <select name="how_to_buy" id="how_to_buy" defaultValue={"main"}>
                <option value="main" disabled className="opc">
                  How to buy
                </option>
                <option value="cash">cash</option>
                <option value="finance">finance</option>
              </select>
            </div>
            <div className="form-group form-group-en">
              <select
                name="housing_support"
                id="housing_support"
                defaultValue={"main"}
              >
                <option value="main" disabled className="opc">
                  Housing support
                </option>
                <option value="Supported">Supported</option>
                <option value="Not supported">Not supported</option>
              </select>
            </div>
            <button type="submit" className="submit-button">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LandingContactEn;
