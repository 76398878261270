import React, { useEffect, useRef } from "react";
import "./Services.css";
import Workflow from "./Workflow";

function Services() {
  const titleRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.01 }
    );

    const title = titleRef.current;
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;

    if (title) {
      title.classList.add("hidden");
      title.dataset.animation = "fade-in-left";
      observer.observe(title);
    }

    if (section1) {
      section1.classList.add("hidden");
      section1.dataset.animation = "fade-in-up";
      observer.observe(section1);
    }

    if (section2) {
      section2.classList.add("hidden");
      section2.dataset.animation = "fade-in-right";
      observer.observe(section2);
    }

    return () => {
      if (title) observer.unobserve(title);
      if (section1) observer.unobserve(section1);
      if (section2) observer.unobserve(section2);
    };
  }, []);

  return (
    <div className="Home-Services" id="Home-Services">
      <h2 className="section-title" ref={titleRef}>
        {" "}
        ماذا نقدم لكم ؟{" "}
      </h2>
      <div className="Home-Services-body">
        <div className="Home-Services-Section" ref={section1Ref}>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-1">
              <h2> الوساطة العقارية</h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  هي واحدة من أهم الخدمات التي تقدمها شركة "فكرة منزل للتسويق
                  العقاري". وتتمثل الوساطة في دور الوسيط بين البائع والمشتري
                  لتسهيل عملية البيع أو التأجير بطريقة مهنية.
                </p>
                <a href="https://wa.me/966566669323" target="_blabk">
                  {" "}
                  لمعرفة المزيد <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-2">
              <h2>التسويق عقاري</h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  {" "}
                  التسويق عقاري تقدم شركة "فكرة منزل" حملات تسويقية رقمية موجهة
                  تهدف إلى جذب العملاء المحتملين. وتشمل هذه الحملات الإعلان عبر
                  الإنترنت على المنصات الاجتماعية مثل إنستجرام و سناب شات وتيك
                  توك وجوجل، ، والظهور على مواقع متخصصة في العقارات.
                </p>
                <a href="https://wa.me/966566669323" target="_blabk">
                  {" "}
                  لمعرفة المزيد <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-3">
              <h2>التصوير العقاري الاحترافي</h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  توفير خدمات تصوير احترافية للعقارات تشمل الصور والفيديوهات
                  عالية الجودة، سواء للأصول السكنية أو التجارية، مما يسهم في
                  إبراز نقاط القوة وإظهارها بصورة لافتة للنظر.
                </p>
                <a href="https://wa.me/966566669323" target="_blabk">
                  {" "}
                  لمعرفة المزيد <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-4">
              <h2> استشارات التسعير وتقييم العقارات</h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  توفر الشركة استشارات حول تقييم العقارات لتحديد الأسعار
                  المثالية بناءً على تحليل السوق والموقع والقيمة المضافة.
                </p>
                <a href="https://wa.me/966566669323" target="_blabk">
                  {" "}
                  لمعرفة المزيد <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <Workflow />
      </div>
    </div>
  );
}

export default Services;
