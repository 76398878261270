import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import "./Partners-en.css";
import "../sections-en.css";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const data = [
  { src: `/partners/1.png`, alt: `Partner` },
  // { src: `/partners/2.png`, alt: `Partner` },
  { src: `/partners/3.png`, alt: `Partner` },
  { src: `/partners/4.png`, alt: `Partner` },
  { src: `/partners/5.png`, alt: `Partner` },
  { src: `/partners/6.png`, alt: `Partner` },
  { src: `/partners/7.png`, alt: `Partner` },
  { src: `/partners/8.png`, alt: `Partner` },
  { src: `/partners/9.png`, alt: `Partner` },
];

function Partners_en() {
  const titleRef = useRef(null);
  const sectionRef = useRef(null);
  const numbersRef = useRef(null);
  const counters = useRef([3200, 11300, 50]);
  const [startCount, setStartCount] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const numberObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStartCount(true);
            numberObserver.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const title = titleRef.current;
    const section = sectionRef.current;
    const numbers = numbersRef.current;

    if (title) {
      title.classList.add("hidden");
      title.dataset.animation = "fade-in-right";
      observer.observe(title);
    }

    if (section) {
      section.classList.add("hidden");
      section.dataset.animation = "fade-in-left";
      observer.observe(section);
    }

    if (numbers) {
      numberObserver.observe(numbers);
    }

    return () => {
      if (title) observer.unobserve(title);
      if (section) observer.unobserve(section);
      if (numbers) numberObserver.unobserve(numbers);
    };
  }, []);

  return (
    <div className="Home-Partners" id="Home-Partners">
      <h2 className="partners-section-title section-title-en" ref={titleRef}>
        {" "}
        Success Partners{" "}
      </h2>

      <div className="partnar__container_custom">
        {data.map((el, idx) => (
          <div className="logos__custom" key={idx}>
            <img src={el.src} alt={el.alt} className="logo__img logo" />
          </div>
        ))}
      </div>

      <div className="Numbers-section" ref={numbersRef}>
        {["Number of units sold", " Our Clients ", "Project Management "].map(
          (title, index) => (
            <div className="number-square" key={index}>
              <h3 className="container-title">{title}</h3>
              <div className="number-container">
                <div className="numbers">
                  {startCount && (
                    <div>
                      <CountUp
                        className="countup"
                        start={0}
                        end={counters.current[index]}
                        duration={3.5}
                      />
                      {/* {index === 0 && <span className="milion">M</span>} */}
                    </div>
                  )}
                </div>
                <span>+</span>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Partners_en;
