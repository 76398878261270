import React, { useEffect, useRef } from "react";
import "./Services.css";
import Workflow from "./Workflow";

function Services_en() {
  const titleRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.01 }
    );

    const title = titleRef.current;
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;

    if (title) {
      title.classList.add("hidden");
      title.dataset.animation = "fade-in-right";
      observer.observe(title);
    }

    if (section1) {
      section1.classList.add("hidden");
      section1.dataset.animation = "fade-in-up";
      observer.observe(section1);
    }

    if (section2) {
      section2.classList.add("hidden");
      section2.dataset.animation = "fade-in-left";
      observer.observe(section2);
    }

    return () => {
      if (title) observer.unobserve(title);
      if (section1) observer.unobserve(section1);
      if (section2) observer.unobserve(section2);
    };
  }, []);

  return (
    <div className="Home-Services" id="Home-Services">
      <h2 className="section-title-en" ref={titleRef}>
        {" "}
        What do we offer you?{" "}
      </h2>
      <body className="Home-Services-body">
        <div className="Home-Services-Section" ref={section1Ref}>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-1">
              <h2> Real Estate Brokerage </h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  One of the most important services offered by "Real Estate
                  House Idea" is real estate brokerage. This service involves
                  acting as an intermediary between sellers and buyers to
                  facilitate the sale or rental process in a professional
                  manner.{" "}
                </p>
                <a href="https://api.whatsapp.com/send/?phone=966566669323">
                  {" "}
                  For more details <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-2">
              <h2> Real Estate Marketing </h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  Real Estate House Idea" provides targeted digital marketing
                  campaigns aimed at attracting potential clients. These
                  campaigns include online advertising on social media platforms
                  like Instagram, Snapchat, TikTok, and Google, along with
                  appearances on specialized real estate websites.{" "}
                </p>
                <a href="https://api.whatsapp.com/send/?phone=966566669323">
                  {" "}
                  For more details <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-3">
              <h2> Professional Photography </h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  The company offers professional photography services for real
                  estate, including high-quality images and videos for both
                  residential and commercial properties. This helps to highlight
                  the strengths and features of the properties in an
                  eye-catching way.{" "}
                </p>
                <a href="https://api.whatsapp.com/send/?phone=966566669323">
                  {" "}
                  For more details <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
          <div className="Service-Box">
            <div className="Service-Title Service-Title-4">
              <h2>Pricing Consultation </h2>
            </div>
            <div className="Service-Description">
              <div className="custom__box">
                <p>
                  The company offers consultations on property valuation to
                  determine the ideal prices based on market analysis, location,
                  and added value
                </p>
                <a href="https://api.whatsapp.com/send/?phone=966566669323">
                  {" "}
                  For more details <span> &larr; </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <Workflow />
      </body>
    </div>
  );
}

export default Services_en;
