import React, { useState, useEffect, useRef } from "react";

function About_en() {
  const [activeSection, setActiveSection] = useState("section1");

  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);
  const selectionSectionRef = useRef(null);
  const h1Ref = useRef(null);
  const pRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const firstSection = firstSectionRef.current;
    const secondSection = secondSectionRef.current;
    const selectionSection = selectionSectionRef.current;
    const h1 = h1Ref.current;
    const p = pRef.current;
    const button = buttonRef.current;

    if (firstSection) {
      firstSection.classList.add("hidden");
      firstSection.dataset.animation = "fade-in-right";
      observer.observe(firstSection);
    }

    if (secondSection) {
      secondSection.classList.add("hidden");
      secondSection.dataset.animation = "fade-in-left";
      observer.observe(secondSection);
    }

    if (selectionSection) {
      selectionSection.classList.add("hidden");
      selectionSection.dataset.animation = "fade-in-bottom";
      observer.observe(selectionSection);
    }

    if (h1) {
      h1.classList.add("hidden");
      h1.dataset.animation = "fade-in-bottom";
      observer.observe(h1);
    }

    if (p) {
      p.classList.add("hidden");
      p.dataset.animation = "fade-in-bottom";
      observer.observe(p);
    }

    if (button) {
      button.classList.add("hidden");
      button.dataset.animation = "fade-in-bottom";
      observer.observe(button);
    }

    return () => {
      if (firstSection) observer.unobserve(firstSection);
      if (secondSection) observer.unobserve(secondSection);
      if (selectionSection) observer.unobserve(selectionSection);
      if (h1) observer.unobserve(h1);
      if (p) observer.unobserve(p);
      if (button) observer.unobserve(button);
    };
  }, []);

  useEffect(() => {
    const animateSections = () => {
      const firstSection = firstSectionRef.current;
      const secondSection = secondSectionRef.current;

      if (firstSection) {
        firstSection.classList.remove("fade-in-right");
        void firstSection.offsetWidth; // Trigger reflow
        firstSection.classList.add("hidden", "fade-in-right");
      }
      if (secondSection) {
        secondSection.classList.remove("fade-in-left");
        void secondSection.offsetWidth; // Trigger reflow
        secondSection.classList.add("hidden", "fade-in-left");
      }

      setTimeout(() => {
        if (activeSection === "section1" || activeSection === "section3") {
          if (firstSection) firstSection.classList.remove("hidden");
          if (secondSection) secondSection.classList.remove("hidden");
        } else if (activeSection === "section2") {
          if (firstSection) firstSection.classList.remove("hidden");
          if (secondSection) secondSection.classList.remove("hidden");
        }
      }, 0);
    };

    animateSections();
  }, [activeSection]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "section1":
        return (
          <div className="about-top-section">
            <section className="right-section" ref={secondSectionRef}>
              <div className="img-wrapper">
                <img
                  className="About-pic"
                  src={require("../../../Assets/Icons/goals.png")}
                  alt="Goals Icon"
                />
              </div>
            </section>
            <section className="left-section" ref={firstSectionRef}>
              <p>
                We aim to be the first choice and trusted partner in delivering
                comprehensive real estate marketing services by building strong
                relationships with our clients and developing innovative
                marketing strategies that contribute to the success of real
                estate projects. We are committed to meeting the growing market
                demands and achieving the highest standards of satisfaction.
              </p>
            </section>
          </div>
        );
      case "section2":
        return (
          <div className="about-top-section">
            <section className="right-section" ref={secondSectionRef}>
              <div className="img-wrapper">
                <img
                  className="About-pic"
                  src={require("../../../Assets/Icons/vision.png")}
                  alt="Vision Icon"
                />
              </div>
            </section>
            <section className="left-section" ref={firstSectionRef}>
              <p>
                Our vision is to become the leading destination in the real
                estate marketing field across the Kingdom of Saudi Arabia,
                contributing to a prosperous and sustainable real estate future.
                We aspire to keep pace with the latest developments in the real
                estate market and continuously offer marketing solutions that
                exceed our clients' expectations.
              </p>
            </section>
          </div>
        );
      case "section3":
        return (
          <div className="about-top-section">
            <section className="right-section" ref={secondSectionRef}>
              <div className="img-wrapper">
                <img
                  className="About-pic"
                  src={require("../../../Assets/Icons/message.png")}
                  alt="Message Icon"
                />
              </div>
            </section>
            <section className="left-section" ref={firstSectionRef}>
              <p>
                Our mission is to provide comprehensive and high-quality real
                estate marketing services based on innovation and excellence. We
                strive to achieve client satisfaction by offering creative
                marketing solutions that enhance the added value of their
                projects, with a commitment to integrity and transparency in all
                our business dealings.
              </p>
            </section>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Home-About" id="Home-About">
      <div className="About-Body">
        <div className="about-bottom-section">
          <div className="info-section">{renderContent()}</div>
          <div className="selector-section" ref={selectionSectionRef}>
            <button
              className={activeSection === "section1" ? "active" : ""}
              onClick={() => handleSectionChange("section1")}
            >
              Our Goals
            </button>
            <button
              className={activeSection === "section2" ? "active" : ""}
              onClick={() => handleSectionChange("section2")}
            >
              Our Vision
            </button>
            <button
              className={activeSection === "section3" ? "active" : ""}
              onClick={() => handleSectionChange("section3")}
            >
              Our Mission
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About_en;
