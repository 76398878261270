import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Home/Footer/Footer";
import { faChevronLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import NavBar from "../Home/Header/NavBar";
import Blog from "../Home/Blog/Blog";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import blogData from "../../pages_ar/Blogs/blogData";

const imgs = [
  { name: "/blogs/1.png" },
  { name: "/blogs/2.png" },
  { name: "/blogs/3.png" },
];
const Blog1 = () => {
  return (
    <div className="Contact-us" id="Blog-page" lang="ar">
      <div className="Contact-us-Header Page-Header">
        <NavBar currentPage="Blog-page" />
        <div className="Contact-us-header-title page-header-title">
          <h2> المقالات</h2>

          <div className="title-nav">
            <a href="/" className="icon-link" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faHome} />
            </a>

            <FontAwesomeIcon className="arrow-link" icon={faChevronLeft} />

            <a href="/">
              <h5> الصفحة الرئيسية</h5>
            </a>
          </div>
        </div>
      </div>

      <div className="Contact-us-body custom__blog-body">
        <h2 className="Interest-section-title section-title">
          استراتيجيات التسويق العقاري الناجحة: دليل شامل
        </h2>
        <div className="Interest-body">
          <div className="custom__blog-post">
            تُعتبر استراتيجيات التسويق العقاري من العناصر الأساسية لنجاح أي شركة
            تعمل في مجال العقارات. مع التنافس الشديد في السوق، يتعين على الشركات
            اعتماد استراتيجيات مبتكرة لجذب العملاء وتحقيق المبيعات. إليك بعض
            الاستراتيجيات الفعالة التي يمكن أن تعزز من أداء التسويق العقاري:
            <br />
            <span>1. التسويق الرقمي</span>
            <br />
            في عصر التكنولوجيا الحديثة، أصبح التسويق الرقمي أداة حيوية. يجب على
            الشركات إنشاء موقع ويب مخصص يكون سهل الاستخدام ويحتوي على معلومات
            دقيقة حول العقارات. يُفضل أن يتضمن الموقع صورًا عالية الجودة ووصفًا
            مفصلًا لكل عقار.
            <br />
            <span>2. تحسين محركات البحث (SEO)</span>
            <br />
            تُعتبر تحسين محركات البحث ضرورية لضمان ظهور موقعك في النتائج الأولى
            عند البحث عن عقارات. يشمل ذلك استخدام الكلمات المفتاحية المناسبة،
            وإنشاء محتوى عالي الجودة، وتحسين سرعة تحميل الموقع.
            <br />
            <span> 3. استخدام وسائل التواصل الاجتماعي</span>
            <br />
            تُعد وسائل التواصل الاجتماعي منصات قوية للوصول إلى جمهور واسع. يجب
            على الشركات التفاعل مع العملاء من خلال نشر محتوى جذاب، مثل صور
            العقارات، والمعلومات عن المشاريع الجديدة، والمناسبات الخاصة.
            <br />
            <span>4. الإعلانات المدفوعة</span>
            <br />
            تساعد الإعلانات المدفوعة عبر الإنترنت في الوصول إلى جمهور مستهدف
            بسرعة. يمكن استخدام Google Ads أو إعلانات Facebook للوصول إلى
            العملاء المحتملين بناءً على اهتماماتهم وموقعهم.
            <br />
            <span>5. التسويق عبر البريد الإلكتروني:</span>
            <br />
            تُعتبر حملات البريد الإلكتروني أداة فعالة للتواصل مع العملاء. يمكنك
            إرسال رسائل إخبارية تحتوي على معلومات حول عقارات جديدة، ونصائح
            عقارية، وعروض خاصة.
            <br />
            <span>الخاتمة</span>
            <br />
            إن نجاح التسويق العقاري يعتمد على القدرة على التكيف مع التغيرات في
            السوق واستخدام الأدوات والتقنيات الحديثة. من خلال اعتماد استراتيجيات
            فعالة، يمكن للشركات تحقيق نتائج مبهرة وزيادة حصتها في السوق.
            <br />
            <span>الكلمات المفتاحية</span>
            <br />
            • التسويق العقاري
            <br />
            • استراتيجيات التسويق
            <br />
            • تحسين محركات البحث
            <br />• وسائل التواصل الاجتماعي
          </div>
        </div>
      </div>

      <div className="Home-Blog" id="Home-Blog">
        <h2 className="Blog-section-title section-title">
          مقالات أخرى ننصح بقرائتها
        </h2>
        <Swiper
          spaceBetween={10}
          slidesPerView={2}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          speed={1000}
          modules={[Autoplay]}
          pagination={{ clickable: true }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 1,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          className="Blog-Section"
        >
          {blogData.map((blog) => (
            <SwiperSlide key={blog.id} className="Blog-Box">
              <img src={blog.image} alt={blog.title} className="Blog-Image" />
              <div className="Blog-Box-content">
                <div className="Blog-Date">{blog.date}</div>
                <div className="Blog-Title">{blog.title}</div>
                <div className="Blog-Paragraph">
                  {blog.paragraph.substring(0, 250)}...
                </div>
                <button className="Blog-Button"> قراءة المزيد ... </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Footer />
    </div>
  );
};

export default Blog1;
