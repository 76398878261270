import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import "./Work.css";

const Work = () => {
  const initialVisibleItems = 8;
  const step = 8;
  const [activeFilter, setActiveFilter] = useState("الكل");
  const [visibleItems, setVisibleItems] = useState(initialVisibleItems);
  const [delayedVisibleItems, setDelayedVisibleItems] =
    useState(initialVisibleItems);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const titleRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const title = titleRef.current;
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;
    const section3 = section3Ref.current;

    if (title) {
      title.classList.add("hidden");
      title.dataset.animation = "fade-in-left";
      observer.observe(title);
    }

    if (section1) {
      section1.classList.add("hidden");
      section1.dataset.animation = "fade-in-up";
      observer.observe(section1);
    }

    if (section2) {
      section2.classList.add("hidden");
      section2.dataset.animation = "fade-in-up";
      observer.observe(section2);
    }

    if (section3) {
      section3.classList.add("hidden");
      section3.dataset.animation = "fade-in-up";
      observer.observe(section3);
    }

    return () => {
      if (title) observer.unobserve(title);
      if (section1) observer.unobserve(section1);
      if (section2) observer.unobserve(section2);
      if (section3) observer.unobserve(section3);
    };
  }, []);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    setVisibleItems(initialVisibleItems);
    setDelayedVisibleItems(0);
    setTimeout(() => setDelayedVisibleItems(initialVisibleItems), 10);
  };

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + step);
    setTimeout(
      () =>
        setDelayedVisibleItems((prevVisibleItems) => prevVisibleItems + step),
      0
    );
  };

  const showLessItems = (e) => {
    e.preventDefault();
    const currentScrollY = window.scrollY;
    setVisibleItems((prevVisibleItems) =>
      Math.max(prevVisibleItems - step, initialVisibleItems)
    );
    setTimeout(
      () =>
        setDelayedVisibleItems((prevVisibleItems) =>
          Math.max(prevVisibleItems - step, initialVisibleItems)
        ),
      0
    );
    window.scrollTo(0, currentScrollY);
  };

  const handleImageClick = (index) => {
    const filteredImages = filteredItems.map((item) => ({
      original: item.src,
      thumbnail: item.src,
      originalAlt: item.category,
      thumbnailAlt: item.category,
    }));
    setLightboxImages(filteredImages);
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const portfolioItems = [
    {
      id: 1,
      category: "فلل",
      src: require("../../../Assets/Pages-images/Home_Slider3.jpg"),
    },
    {
      id: 2,
      category: "فلل",
      src: require("../../../Assets/Pages-images/Home_Slider3.jpg"),
    },
    {
      id: 3,
      category: "فلل",
      src: require("../../../Assets/Pages-images/Home_Slider3.jpg"),
    },
    {
      id: 4,
      category: "فلل",
      src: require("../../../Assets/Pages-images/Home_Slider3.jpg"),
    },
    {
      id: 5,
      category: "شقق",
      src: require("../../../Assets/Pages-images/Home_Slider2.jpg"),
    },
    {
      id: 6,
      category: "شقق",
      src: require("../../../Assets/Pages-images/Home_Slider2.jpg"),
    },
    {
      id: 7,
      category: "شقق",
      src: require("../../../Assets/Pages-images/Home_Slider2.jpg"),
    },
    {
      id: 8,
      category: "شقق",
      src: require("../../../Assets/Pages-images/Home_Slider2.jpg"),
    },
    {
      id: 9,
      category: "شقق",
      src: require("../../../Assets/Pages-images/Home_Slider2.jpg"),
    },
    {
      id: 10,
      category: "مكاتب",
      src: require("../../../Assets/Pages-images/Home_Slider1.jpg"),
    },
    {
      id: 11,
      category: "مكاتب",
      src: require("../../../Assets/Pages-images/Home_Slider1.jpg"),
    },
    {
      id: 12,
      category: "مكاتب",
      src: require("../../../Assets/Pages-images/Home_Slider1.jpg"),
    },
    {
      id: 13,
      category: "اخرى",
      src: require("../../../Assets/Pages-images/Home_Slider4.jpg"),
    },
    {
      id: 14,
      category: "اخرى",
      src: require("../../../Assets/Pages-images/Home_Slider4.jpg"),
    },
  ];

  const filteredItems =
    activeFilter === "الكل"
      ? portfolioItems
      : portfolioItems.filter((item) => item.category === activeFilter);

  console.log(filteredItems);

  return (
    <div className="Home-Work" id="Home-Work">
      <h2 className="Work-section-title section-title" ref={titleRef}>
        {" "}
        اعمالنا{" "}
      </h2>
      <ul className="portfolio-filter" ref={section1Ref}>
        <li
          className={`list ${
            activeFilter === "الكل" ? "portfolio-filter-active" : ""
          }`}
          onClick={() => handleFilterClick("الكل")}
          data-filter="الكل"
        >
          {" "}
          الكل{" "}
        </li>
        <li
          className={`list ${
            activeFilter === "فلل" ? "portfolio-filter-active" : ""
          }`}
          onClick={() => handleFilterClick("فلل")}
          data-filter="فلل"
        >
          {" "}
          فلل{" "}
        </li>
        <li
          className={`list ${
            activeFilter === "شقق" ? "portfolio-filter-active" : ""
          }`}
          onClick={() => handleFilterClick("شقق")}
          data-filter="شقق"
        >
          {" "}
          شقق{" "}
        </li>
        <li
          className={`list ${
            activeFilter === "مكاتب" ? "portfolio-filter-active" : ""
          }`}
          onClick={() => handleFilterClick("مكاتب")}
          data-filter="مكاتب"
        >
          {" "}
          مكاتب{" "}
        </li>
        <li
          className={`list ${
            activeFilter === "اخرى" ? "portfolio-filter-active" : ""
          }`}
          onClick={() => handleFilterClick("اخرى")}
          data-filter="اخرى"
        >
          {" "}
          اخرى{" "}
        </li>
      </ul>

      <Swiper
        className="mySwiper"
        slidesPerView={4}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        speed={1000}
        modules={[Autoplay]}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          350: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
      >
        {filteredItems.map((item, index) => (
          <SwiperSlide
            key={index}
            className={`portfolio-box portfolio-box__custom ${item.category} show`}
            onClick={() => handleImageClick(index)}
          >
            <div>
              <img alt="" src={item.src} />
              <div className="portfolio__body">
                <h2>عنوان أو أسم للصورة</h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <Swiper
        spaceBetween={10}
        slidesPerView={2}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        speed={1000}
        modules={[Autoplay]}
        pagination={{ clickable: true }}
        breakpoints={{
          1024: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        className="portfolio-container portfolio-container__custom"
      >
        {filteredItems.map((item, index) => (
          <SwiperSlide key={index} className={`custom__slider`}>
            <div
              key={item.id}
              className={`portfolio-box portfolio-box__custom ${item.category} show`}
              onClick={() => handleImageClick(index)}
            >
              <img alt="" src={item.src} />
              <div className="portfolio__body">
                <h2>عنوان أو أسم للصورة</h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper> */}

      {/* <div className="portfolio-container" ref={section2Ref}>
        {filteredItems.slice(0, visibleItems).map((item, index) => (
          <div
            key={item.id}
            className={`portfolio-box portfolio-box__custom ${item.category} ${
              index < delayedVisibleItems ? "show" : ""
            }`}
            onClick={() => handleImageClick(index)}
          >
            <img alt="" src={item.src} />
            <div className="portfolio__body">
              <h2>عنوان أو أسم للصورة</h2>
            </div>
          </div>
        ))}
      </div> */}

      {lightboxOpen && (
        <div className="lightbox-container">
          <div className="close-button" onClick={closeLightbox}>
            X
          </div>
          <ImageGallery
            items={lightboxImages}
            startIndex={photoIndex}
            onClose={closeLightbox}
            showThumbnails={true}
            showPlayButton={false}
            showFullscreenButton={true}
            lazyLoad={true}
            useBrowserFullscreen={false}
          />
        </div>
      )}
    </div>
  );
};

export default Work;
